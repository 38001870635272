import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { filter, Subject, take, takeUntil } from 'rxjs';
import { SplashScreenStateService } from 'src/app/core/splash-screen-state.service';
import { MenuLayoutService } from 'src/app/layout/layouts/menu-layout/menu-layout.service';
import { MenuCardService } from '../menu-card.service';
import { PartnerService } from '../../partner/partner.service';

@Component({
  selector: 'app-menu-card-home',
  templateUrl: './menu-card-home.component.html',
  styleUrls: ['./menu-card-home.component.scss']
})
export class MenuCardHomeComponent implements OnInit {
  hotelData;

  categories: any[] = []
  menuTypes: any[] = [];
  serviceTypes: any[] = [];

  selectedMenuType: any;

  hasError: boolean;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private _menuCardService: MenuCardService,
    private _activeRoute: ActivatedRoute,
    private menuLayoutService: MenuLayoutService,
    private splashScreenStateService: SplashScreenStateService,
    private ngxUiService: NgxUiLoaderService,
    @Inject('HOTELDATA') private apiURL: string
  ) {

    this._activeRoute.queryParams.subscribe((param) => {
      if (param['type']) {
        this.selectedMenuType = param['type']
      }
    })

    // Getting hotel data
    menuLayoutService.hotelData.pipe(
      takeUntil(this._unsubscribeAll)).subscribe(data => {
        this.hotelData = data
      }, (error) => {
        this.hasError = true
      })
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  ngOnInit(): void {
    this.ngxUiService.startLoader('loader-02')

    Promise.all([
      // Get menu types
      this.menuLayoutService.getMenuTypes().then((data: any) => { this.menuTypes = data }),
      // Service types
      this.menuLayoutService.getServiceTypeList(this.hotelData.business_branch.business_branch_key).then((types: any) => { this.serviceTypes = types })
    ]).then(() => {
      setTimeout(() => {
        this.splashScreenStateService.stop()
      }, 2000);
    })
      .finally(() => {
        this.ngxUiService.stopLoader('loader-02')
      })
  }

  /**
   * Select menu type
   */
  setMenuType(menuType) {
    this.selectedMenuType = menuType
  }

  /**
   * On destroy
   */
  ngOnDestroy() {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
