import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GetCartCountPipe } from './get-cart-count.pipe';
import { FindInArrayPipe } from './find-in-array.pipe';
import { CommaFromArrayPipe } from './comma-from-array.pipe';
import { GetFoodAvailabilityPipe } from './get-food-availability.pipe';
import { IsArrayPipe } from './check-array-pipe';
import { ProperCasePipe } from './proper-case.pipe';



@NgModule({
  declarations: [
    GetCartCountPipe,
    FindInArrayPipe,
    CommaFromArrayPipe,
    GetFoodAvailabilityPipe,
    IsArrayPipe,
    ProperCasePipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    GetCartCountPipe,
    FindInArrayPipe,
    CommaFromArrayPipe,
    GetFoodAvailabilityPipe,
    IsArrayPipe,
    ProperCasePipe
  ]
})
export class PipeModule { }
