import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'properCase'
})
export class ProperCasePipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {
    let newStr = value.replace('-', ' ').replace('_', ' ')
    return newStr.charAt(0).toUpperCase() + newStr.slice(1);
  }

}
