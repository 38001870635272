<div class="spark-splash-screen hotel-splash-screen z-30" [style.background-color]="scheme=='dark'?'#292929':'white'"
  *ngIf="showSplash">
  <!-- Splash screen -->
  <div class="center">
    <div class="spinner-logo-box">
      <div class="flex flex-col spinner-logo items-center justify-center m-auto md:w-1/2 w-52">
        <img id="" [class.rounded-full]="hotelData?.business_logo"
          [src]="hotelData?.business_logo?hotelData?.business_logo:'assets/logo/orderplzz-full-logo.svg'">
        <div class="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-container *ngIf="!showSplash">
  <mat-sidenav-container class="sidebar-container mat-app-background" [class.dark]="config.scheme === 'dark'"
    *transloco="let t">
    <!-- Setting sidebar -->
    <mat-sidenav class="md:w-full w-96" mode="over" #rightDrawer position="end">
      <div class="flex flex-row items-center px-6 h-20 min-h-20 text-white bg-primary">
        <mat-icon class="icon-size-7 text-current">settings</mat-icon>
        <div class="ml-3 text-2xl tracking-tight">{{t('settings')}}</div>
        <button mat-icon-button (click)="rightDrawer.toggle();"
          class="mat-focus-indicator ml-auto mat-icon-button mat-button-base">
          <mat-icon role="img" class="mat-icon notranslate text-current mat-icon-no-color" aria-hidden="true"
            data-mat-icon-type="svg" data-mat-icon-name="x" data-mat-icon-namespace="heroicons_outline">
            close
          </mat-icon>
        </button>
      </div>
      <div class="setting-sidebar dark:bg-gray-800 h-full">
        <div class="text-xl font-medium text-secondary">{{t('scheme')}}</div>
        <div class="grid grid-cols-2 gap-3 justify-items-start mt-6">
          <!-- Dark -->
          <div class="flex items-center py-3 pl-5 pr-6 rounded-full cursor-pointer ring-inset ring-primary bg-hover"
            [class.ring-2]="config.scheme === 'dark'" (click)=" setScheme('dark')">
            <div class="flex items-center rounded-full overflow-hidden">
              <mat-icon class="icon-size-5">dark_mode</mat-icon>
            </div>
            <div class="flex items-center ml-2 font-medium leading-5"
              [class.text-secondary]="!(config.scheme === 'dark')">
              {{t('dark')}}
            </div>
          </div>
          <!-- Light -->
          <div class="flex items-center py-3 pl-5 pr-6 rounded-full cursor-pointer ring-inset ring-primary bg-hover"
            [class.ring-2]="config.scheme === 'light'" (click)="setScheme('light')">
            <div class="flex items-center rounded-full overflow-hidden">
              <mat-icon class="icon-size-5">light_mode</mat-icon>
            </div>
            <div class="flex items-center ml-2 font-medium leading-5" [class.text-secondary]="config.scheme === 'dark'">
              {{t('light')}}
            </div>
          </div>
        </div>
        <!-- <hr class="my-8">
        <div class="text-xl font-medium text-secondary">{{t('session')}}</div>
        <div class="grid grid-cols-2 gap-3 justify-items-start mt-6">
          <div class="flex items-center py-3 pl-5 pr-6 rounded-full cursor-pointer ring-inset ring-primary bg-hover"
            [class.ring-2]="config.idleTime === 600" (click)="setSession(600)">
            <div class="flex items-center rounded-full overflow-hidden">
              <mat-icon class="icon-size-5">schedule</mat-icon>
            </div>
            <div class="flex items-center ml-2 font-medium leading-5"
              [class.text-secondary]="(config.idleTime === 600)">
              10 min
            </div>
          </div>
          <div class="flex items-center py-3 pl-5 pr-6 rounded-full cursor-pointer ring-inset ring-primary bg-hover"
            [class.ring-2]="config.idleTime === 900" (click)="setSession(900)">
            <div class="flex items-center rounded-full overflow-hidden">
              <mat-icon class="icon-size-5">schedule</mat-icon>
            </div>
            <div class="flex items-center ml-2 font-medium leading-5" [class.text-secondary]="config.idleTime === 900">
              90 min
            </div>
          </div>
        </div> -->

        <hr class="my-8">
        <div class="text-xl font-medium text-secondary">{{t('theme')}}</div>
        <div class="grid grid-cols-2 gap-3 justify-items-start mt-6">
          <ng-container *ngFor="let theme of themes">
            <div
              class="flex w-full items-center px-4 py-3 rounded-full cursor-pointer ring-inset ring-primary bg-gray-100 dark:bg-gray-800"
              [class.ring-2]="config.partnerTheme == theme[0]" (click)="setTheme(theme[0])">
              <div>
                <div class="flex-0 w-3 h-3 rounded-full" [style.background-color]="theme[1].primary">
                </div>
              </div>
              <div class="ml-2.5 font-medium leading-5 truncate" [class.text-secondary]="config.theme !== theme[0]">
                {{_utility.toProperCase(theme[0]) | titlecase}}
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </mat-sidenav>
    <mat-sidenav-content>
      <mat-sidenav-container class="sidebar-container mat-app-background" [class.dark]="config.scheme === 'dark'">
        <mat-sidenav [class.sidebar-fold]="isSidebarFold" [class.sidebar-unfold]="!isSidebarFold" #drawer
          [opened]="!sidebarClosed" [mode]="mode">
          <app-navigation class="flex-1" [navigation]="navigation">
            <!-- Navigation header -->
            <ng-container navigationHeader>
              <div class="nav-header">
                <div class="company-profile mt-2 flex items-center" [class.justify-center]="isSidebarFold">
                  <img class="rounded-full"
                    src="{{hotelData?.business_logo?hotelData?.business_logo:'assets/logo/orderplzz-logo.png'}}" alt="">
                  <h4 class="ml-1.5 text-left leading-snug overflow-hidden" *ngIf="!isSidebarFold">
                    <span>{{userData?.user_login?.user_role_name?.user_role_name}}</span>
                    <div class="text-sm overflow-ellipsis overflow-hidden whitespace-nowrap"
                      [matTooltip]="hotelData.business_name">
                      {{ hotelData.business_name }}</div>
                  </h4>
                </div>
                <div class="dashboard-info" *ngIf="!isSidebarFold">
                  <h3>{{userData.full_name}}</h3>
                  <p class="last-login secondary-text"> {{t('last_login_at_time',
                    {time: (userData?.user_login_activity?.created_at | date:'d MMM yy h:mm a') ??
                    t('few_moments_ago')})}}</p>
                </div>
              </div>
            </ng-container>
            <!-- Navigation footer -->
            <ng-container navigationFooter>
              <div class="nav-header-footer secondary-text" *ngIf="!isSidebarFold">
                <h4 class="">V {{appVersion}}</h4>
                <h4 class="mt-0 leading-snug">{{t('powered_by')}} <a href="https://thesparktech.com/"
                    target="_blank">{{t('spark_technologies')}}</a> </h4>
              </div>
            </ng-container>
          </app-navigation>
        </mat-sidenav>
        <!-- Router area -->
        <mat-sidenav-content [ngStyle]="{'margin-left.px':contentMargin}">
          <ng-container *ngTemplateOutlet="toolbar"></ng-container>
          <div class="router-div z-10" style="overflow: auto; height: 92%;">
            <router-outlet *ngIf="true"></router-outlet>
          </div>
        </mat-sidenav-content>
      </mat-sidenav-container>
    </mat-sidenav-content>
  </mat-sidenav-container>

  <!-- Toolbar -->
  <ng-template #toolbar>
    <mat-toolbar>
      <ng-container *transloco="let t">
        <!-- Humberger menu -->
        <div class="left">
          <button mat-icon-button (click)="foldSidebar()">
            <mat-icon aria-hidden="false">menu</mat-icon>
          </button>
        </div>
        <div class="right partner-menu-bar flex mr-2 items-center">
          <!-- Language -->
          <div>
            <button mat-button class="text-white min-w-0 sm:py-2" [matMenuTriggerFor]="languages">
              <span class="flex items-center">
                <span class="relative w-6 shadow rounded-sm overflow-hidden">
                  <span class="absolute inset-0 ring-1 ring-inset ring-black ring-opacity-10"></span>
                  <img class="w-6" [src]="'assets/icons/'+languageCtrl.value?.icon+'.jpg'">
                </span>
                <span class="ml-3 sm:hidden">{{languageCtrl.value.language_name}}</span>
              </span>
            </button>
            <!-- Language menu -->
            <mat-menu [xPosition]="'before'" #languages="matMenu">
              <ng-container *ngFor="let lang of availablelanguages">
                <button mat-menu-item (click)="setLanguage(lang.language_code)">
                  <ng-container *ngTemplateOutlet="flagImage; context: {$implicit: lang}"></ng-container>
                </button>
              </ng-container>
            </mat-menu>

            <!-- Flag image template -->
            <ng-template let-lang #flagImage>
              <span class="flex items-center">
                <span class="relative w-6 shadow rounded-sm overflow-hidden">
                  <span class="absolute inset-0 ring-1 ring-inset ring-black ring-opacity-10"></span>
                  <img class="w-6" [src]="'assets/icons/'+lang?.icon+'.jpg'">
                </span>
                <span class="ml-3">{{lang.language_name}}</span>
              </span>
            </ng-template>
          </div>

          <!-- User account menu -->
          <mat-menu #menu="matMenu" class="profile-menu">
            <span>
              <div class="flex items-center px-4 py-4 dark:text-white">
                <div class="user-circle-avatar menu-user-circle-avatar">
                  <img
                    src="{{ userData?.profile_photo_path ? userData?.profile_photo_path : 'assets/placeholder/user.jpg'}}">
                  <mat-icon *ngIf="!userData?.profile_photo_path && !userData?.first_name">
                    person
                  </mat-icon>
                </div>
                <div class="ml-3">
                  <span class="text-base font-medium">{{userData?.first_name}}
                    {{userData?.last_name}}</span>
                  <p class="mt-0 text-sm">{{userData?.user_login?.username}}
                    <span
                      class="text-xs">({{userData?.user_login.user_role_name.user_role_drc_key==1?'Business':"Personal"}})</span>
                  </p>
                </div>
              </div>
              <div class="text-center mb-3 mt-1 dark:text-white">
                <!-- <button mat-button>{{t('my_profile')}}</button> -->
                <button mat-stroked-button (click)="goToMyAccount('product','business')"
                  class="rounded-full font-normal">{{t('auth.title.manage_my_business')}}</button>
              </div>
              <button mat-menu-item (click)="switchAccount(i)" *ngFor="let user of accountList;let i=index"
                class="flex items-center w-full">
                <div>
                  <mat-icon class="material-icons-outlined overlay-menu-icon" *ngIf="user.role==2">
                    account_box</mat-icon>
                  <mat-icon class="material-icons-outlined overlay-menu-icon" *ngIf="user.role==1">
                    assignment_ind</mat-icon>
                </div>
                <div class="">
                  <span>
                    {{user.full_name}}
                  </span>
                  <span class="text-xs">({{user.role==1?'Business':"Personal"}})</span>
                </div>
                <mat-icon class="material-icons-outline mr-0 icon-size-5 overlay-menu-icon ml-2">
                  open_in_new</mat-icon>
              </button>

              <button mat-menu-item class="align-middle" *ngIf="accountList.length<10" (click)="addNewAccount()">
                <mat-icon class="material-icons-outlined overlay-menu-icon">person_add
                </mat-icon>{{t('auth.title.sign_with_different_account')}}
                <mat-icon class="icon-size-5 ml-2">
                  open_in_new</mat-icon>
              </button>

              <button mat-menu-item (click)="goToMyAccount()">
                <mat-icon class="material-icons-outlined overlay-menu-icon">account_circle</mat-icon>
                {{t('my_profile')}} <mat-icon class="icon-size-5 ml-2">open_in_new</mat-icon>
              </button>
              <button mat-menu-item (click)="logout()">
                <mat-icon class="material-icons-outlined overlay-menu-icon">logout</mat-icon>
                {{t('auth.title.logout')}}
              </button>
            </span>
          </mat-menu>

          <!-- Notification -->
          <button class="mr-2" [matMenuTriggerFor]="notificationMenu" mat-icon-button (click)="isNotifyStart=false">
            <mat-icon *ngIf="!unreadCount" matBadgeColor="warn">
              notifications</mat-icon>
            <mat-icon *ngIf="unreadCount" [matBadge]="unreadCount<100?unreadCount:'99+'" [class.notify]="isNotifyStart"
              matBadgeColor="warn">
              notifications_active</mat-icon>
          </button>

          <!-- Notification menu -->
          <mat-menu #notificationVertMenu="matMenu">
            <button mat-menu-item *ngIf="unreadCount" (click)="markAllRead()">
              <mat-icon>drafts</mat-icon>
              <span>{{t('notification.title.mark_all_as_read')}}</span>
            </button>
            <button mat-menu-item (click)="showAllNotifications()">
              <mat-icon>apps</mat-icon>
              <span>{{t('notification.title.see_all_notifications')}}</span>
            </button>
          </mat-menu>
          <mat-menu #notificationMenu="matMenu" class="mat-app-background notification-tray h:80" xPosition="before">
            <div (click)="$event.stopPropagation()">
              <!-- Header -->
              <div class=" flex flex-shrink-0 items-center py-4 pr-4 pl-6 bg-primary">
                <div class="hidden -ml-1 mr-3">
                  <button mat-icon-button>
                    <mat-icon class="icon-size-5 text-white">
                      close
                    </mat-icon>
                  </button>
                </div>
                <h2 class="text-lg font-medium leading-10 text-white">
                  {{t('notification.message.notifications')}}
                </h2>
                <!-- <div class="ml-auto">
                                <button mat-icon-button *ngIf="calculateUnread()" (click)="markAllRead()"
                                    [matTooltip]="'Mark all as read'">
                                    <mat-icon class="icon-size-5 text-current">drafts</mat-icon>
                                </button>
                            </div> -->
                <button class="ml-auto text-white" mat-icon-button [matMenuTriggerFor]="notificationVertMenu">
                  <mat-icon>more_vert</mat-icon>
                </button>
              </div>

              <!-- Content -->
              <div style="max-height: 400px;">
                <div class="relative overflow-y-auto pt-2">
                  <!-- Notifications -->
                  <ng-container *ngFor="let notificationItem of notifications">
                    <p *ngIf="notificationItem.data?.length>0" class="mt-2 ml-4">{{ notificationItem.day }}
                    </p>
                    <ng-container *ngFor="let notification of notificationItem.data;let i=index">
                      <div class="flex group mat-menu-item">
                        <!-- Title, description & time -->
                        <div class="flex flex-col flex-auto whitespace-normal">
                          <ng-container *ngIf="notification.notification_title">
                            <div class="flex items-center">
                              <div class="flex-initial w-1/5 text-center">
                                <mat-icon [style.color]="notification.type.color_code">{{
                                  notification.type.icon}}</mat-icon>
                              </div>
                              <div>
                                <div class="font-medium text-base line-clamp-1"
                                  [innerHTML]="notification.notification_title">
                                </div> <ng-container *ngIf="notification.notification_body">
                                  <div class="line-clamp-2 leading-5" [innerHTML]="notification.notification_body">
                                  </div>
                                </ng-container>
                                <div class="mt-1 text-sm leading-none secondary-text">
                                  {{notification.notified_at | date:'MMM dd, h:mm a'}}
                                </div>
                              </div>
                            </div>
                          </ng-container>
                        </div>
                        <div class="flex flex-row" [ngClass]="{'unread': !notification.notification_status}">
                          <!-- Actions -->
                          <div class="relative flex flex-col ml-2">
                            <!--  Indicator  -->
                            <button class="w-6 h-6 min-h-6" mat-icon-button
                              [matTooltip]="notification.notification_status ? 'Mark as unread' : 'Mark as read'"
                              (click)="markRead(notification.notification_key,notification.notification_status)">
                              <span class="w-2 h-2 rounded-full block m-auto" [ngClass]="{'bg-gray-400 dark:bg-gray-500 sm:opacity-0 sm:group-hover:opacity-100': notification.notification_status==1,
                                                            'bg-primary': notification.notification_status==0}"></span>
                            </button>
                            <button class="w-6 h-6 min-h-6 sm:opacity-0 sm:group-hover:opacity-100 leading-none mt-2"
                              mat-icon-button [matTooltip]="'Remove'"
                              (click)="removeNotification(notification.notification_key)">
                              <mat-icon class="icon-size-4 mr-0"> close
                              </mat-icon>
                            </button>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                  <div *ngIf="notifications?.length>0" class="p-4 text-center">
                    <span class="cursor-pointer text-blue-500"
                      (click)="showAllNotifications()">{{t('notification.title.see_all')}}</span>
                  </div>
                  <!-- No notifications -->
                  <ng-container *ngIf="!notifications || !notifications.length">
                    <div class="flex flex-col flex-auto items-center justify-center sm:justify-start py-12 px-8">
                      <div class="flex flex-0 items-center justify-center w-14 h-14 rounded-full bg-primary-100">
                        <mat-icon class="text-black">notifications</mat-icon>
                      </div>
                      <div class="mt-5 text-xl font-medium tracking-tight">
                        {{t('notification.message.no_notifications')}}</div>
                      <div class="w-full max-w-60 mt-1 text-md text-center text-secondary">
                        {{t('notification.message.when_you_have_notifications_they_will_appear_here')}}
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </mat-menu>

          <!-- Settings icon -->
          <button mat-icon-button (click)="openSettings()">
            <mat-icon class="text-white icon-size-5.2">settings
            </mat-icon>
          </button>

          <!-- Share icon -->
          <button mat-icon-button (click)="shareDialog()">
            <mat-icon class="text-white icon-size-5.1">share
            </mat-icon>
          </button>

          <!-- User avatar and name-->
          <button class="my-account-menu" mat-button [matMenuTriggerFor]="menu">
            <div class="user-circle-avatar">
              <img
                src="{{ userData?.profile_photo_path ? userData?.profile_photo_path : 'assets/placeholder/user.jpg'}}">
              <mat-icon *ngIf="!userData?.profile_photo_path && !userData?.first_name">person</mat-icon>
            </div>
            <h3 class="user-name ml-2 sm:hidden text-right leading-tight">{{userData?.first_name}}
            </h3>
          </button>
        </div>
      </ng-container>
    </mat-toolbar>
    <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
  </ng-template>
</ng-container>