export interface config {
  navbar: {
    color: any;
    hidden: boolean;
  };
  toolbar: {
    color: any;
    hidden: boolean;
  },
  layout,
  userTheme,
  partnerTheme,
  scheme,
  idleTime,
  logo1,
  logo2,
  userLanguage,
  partnerLanguage,
  partnerLangKey
}
export const sparkConfig: config = {
  navbar: {
    color: '',
    hidden: false,
  },
  toolbar: {
    color: '',
    hidden: false,
  },
  logo1: 'assets/logo/spark-tech-logo.png',
  logo2: 'assets/logo/spark.png',
  userTheme: 'saffron',
  partnerTheme: 'saffron',
  layout: 'default',
  scheme: 'light',
  idleTime: 600,
  userLanguage: "en-in",
  partnerLanguage: "en-in",
  partnerLangKey: null
}
