/**
 * Copyrights Reserved 2023 OrderPlzz!
 *
 * @author SPARK Technologies
 * @category Layout
 * @copyright Copyrights Reserved By SPARK Technologies 2018-2023
 * @since 2023
 * @version 1.0.0
 *
 */

import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { MatDialog } from '@angular/material/dialog';
import { MatDrawerMode, MatDrawer } from '@angular/material/sidenav';
import { Router, ActivatedRoute } from '@angular/router';
import { Idle } from '@ng-idle/core';
import { TranslocoService } from '@ngneat/transloco';
import { OverlayContainer } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { SparkNavigationService } from 'src/@spark/components/navigation/navigation.service';
import { SparkConfigService } from 'src/@spark/services/config/config.service';
import { MatchMediaService } from 'src/@spark/services/match-media.service';
import { TailwindService } from 'src/@spark/services/tailwind.service';
import { UtilityService } from 'src/app/core/common-service/utility.service';
import { NotificationService } from 'src/app/core/messaging/notification.service';
import { NavigationService } from 'src/app/core/navigation/navigation.service';
import { RouteSplashScreenStateService } from 'src/@spark/services/route-splash-screen-state.service';
import { SplashScreenStateService } from 'src/app/core/splash-screen-state.service';
import { UserService } from 'src/app/core/user/user.service';
import { environment } from 'src/environments/environment';
import { LayoutService } from '../../layout.service';
import { AdminLayoutService } from './admin-layout.service';
import { Title } from '@angular/platform-browser';
import { AdminAuthService } from 'src/app/core/admin-auth/admin-auth.service';
import { CommonApiService } from 'src/app/core/common-service/common-api.service';
import { FormControl } from '@angular/forms';
import { AllNotificationsComponent } from './all-notifications/all-notifications.component';
import { CheckMenuTypeModalComponent } from './check-menu-type-modal/check-menu-type-modal.component';
import { PartnerService } from 'src/app/components/partner/partner.service';
import { MessagingService } from 'src/app/core/messaging/messaging.service';
import { ShareModalComponent } from './share-modal/share-modal.component';
import moment from 'moment';
import { TourModalComponent } from './tour-modal/tour-modal.component';

@Component({
  selector: 'app-admin',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit {
  orgURL = environment.orgURL
  appVersion = environment.appVersion;
  contentMargin = 270;
  mode: MatDrawerMode = 'side';
  scheme: 'dark' | 'light';
  config;
  isSidebarFold = false;
  sidebarClosed = false;
  isDarkMode: boolean = false;
  isNotifyStart: boolean = false;
  showSplash
  isLoading;
  userData;
  layout;
  hotelData;
  currentYear = new Date().getFullYear()
  unreadCount = 0;

  navigation = []
  notifications: any = [];
  partnerTheme: any[] = [];
  partnerLanguage: any;
  themes: [string, any][];
  accountList: any[] = [];
  availablelanguages: any = [];

  languageCtrl: FormControl = new FormControl('');

  personalKey = "personalSecret"
  personalRoleKey = this._utility.encrypt("personal")
  businessRoleKey = this._utility.encrypt("business")

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private _sparkNavigationService: SparkNavigationService,
    private _navigationService: NavigationService,
    private _matchMedia: MatchMediaService,
    private _mediaObserver: MediaObserver,
    private _router: Router,
    private _sparkConfigService: SparkConfigService,
    private _authService: AdminAuthService,
    private _userService: UserService,
    private _activatedRoute: ActivatedRoute,
    public _utility: UtilityService,
    private _translocoService: TranslocoService,
    private _tailwindConfigService: TailwindService,
    private _layoutService: LayoutService,
    private _notificationService: NotificationService,
    private _messagingService: MessagingService,
    private _adminLayoutService: AdminLayoutService,
    private splashScreenStateService: SplashScreenStateService,
    public dialog: MatDialog,
    private commonApiService: CommonApiService,
    private idle: Idle,
    private partnerService: PartnerService,
    private titleService: Title,
    private renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: any,
    private overlayContainer: OverlayContainer) {

    // Get hotel data
    this._adminLayoutService.hotelSubscription.subscribe(data => {
      this.hotelData = data
    })

    // Set splash screen
    this.splashScreenStateService.subject.subscribe((data) => {
      this.showSplash = data
    })

    // Navigation
    this._navigationService.navigation$.subscribe((data: any) => {
      this.navigation = data
    })
  }

  @ViewChild('drawer') drawer: MatDrawer
  @ViewChild('rightDrawer') rightDrawer: MatDrawer

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  ngOnInit() {
    if (!(window.hasOwnProperty('Tawk_API') && (Object.keys(window['Tawk_API']).length != 0))) {
      const s = this.renderer2.createElement('script');
      s.text = `
      var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
      (function(){
        var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
        s1.async=true;
        s1.src='https://embed.tawk.to/`+ environment.tawkPropertyId + `/` + environment.tawkWidgetId + `';
        s1.charset='UTF-8';
        s1.setAttribute('crossorigin','*');
        s0.parentNode.insertBefore(s1,s0);
      })();`
      this.renderer2.appendChild(document.body, s);
    }
    else
      window['Tawk_API'].showWidget()

    // Set favicon
    document.getElementById('appFavicon')?.setAttribute('href', 'assets/favicon.ico');

    // Check menu type
    this.partnerService.checkFoodMenuType().then((data: any) => {

      if (!window.localStorage.getItem('tour'))
        setTimeout(() => {
          this.tourDialog().afterClosed().subscribe(result => {
            if (!data) setTimeout(() => this.checkMenuTypeDialog(), 2000)
          });
        }, 3000)

      // Open menu type
      if (!data && window.localStorage.getItem('tour'))
        setTimeout(() => this.checkMenuTypeDialog(), 3000)
    })

    // Get languages
    this.commonApiService.getLanguages('partner').then((data: any) => {
      this.availablelanguages = data
      let langArray: any[] = []
      this.availablelanguages.forEach((lang) => {
        langArray.push({
          id: lang.language_code,
          label: lang.language_name,
          icon: lang?.icon,
          key: lang.language_key
        })
      })

      // Set all languages to transloco
      this._translocoService.setAvailableLangs(langArray)

      // Set active language
      let lang = this.availablelanguages.find((la) => la.language_code == this.partnerLanguage.toString())
      this._translocoService.setActiveLang(lang.language_code)
      this._utility.setConfig('partnerLangKey', lang.language_key)
      this.languageCtrl.patchValue(lang)
    })

    // Get the themes
    this._tailwindConfigService.tailwindConfig$.pipe(
      takeUntil(this._unsubscribeAll)).subscribe((config) => {
        this.themes = Object.entries(config.themes);
      });

    this._sparkConfigService.config$.pipe(
      takeUntil(this._unsubscribeAll)).subscribe((config: any) => {
        this.config = config;
        this.scheme = config.scheme;
        this.layout = config.layout;
        this.partnerTheme = config.partnerTheme;
        this.partnerLanguage = config.partnerLanguage
        this._updateScheme();
        this._updateLayout();
        this._updateTheme();
      });

    // Update layout and close drawer on mobile
    this._router.events.subscribe(() => {
      this._updateLayout();
      if (this.drawer) {
        const isActive = this._mediaObserver.isActive('lt-md');
        if (isActive) {
          this.drawer.close();
        }
      }
    });

    // Get notifications
    this._notificationService.notifications$.pipe(
      takeUntil(this._unsubscribeAll)).subscribe((data) => {
        if (data) {
          this.notifications = data.data
          this.notifications =
            [
              {
                day: 'Today',
                data: data.data.filter((notification) => moment(notification.notified_at).isSame(new Date(), 'day'))
              },
              {
                day: 'Yesterday',
                data: data.data.filter((notification) => moment(notification.notified_at).isSame(moment().subtract(1, 'day'), 'day'))
              },
              {
                day: 'Earlier',
                data: data.data.filter((notification) => moment(notification.notified_at).isBefore(moment().subtract(1, 'day'), 'day'))
              }]
          if (data.total > this.unreadCount && this.unreadCount > 0) {
            var audio = new Audio("assets/audio/bell-123742.mp3");
            audio.play()
            this.isNotifyStart = true
          }
          this.unreadCount = data.total
        }
        else {
          this.notifications = []
          this.unreadCount = 0
        }
      })

    // Observe media size change
    this._matchMedia.onMediaChange.subscribe((data) => {
      // Get the active status
      const isActive = this._mediaObserver.isActive('lt-md');
      if (isActive) {
        this.sidebarClosed = true;
        this.mode = 'over';
      }
      else {
        this.sidebarClosed = false;
        this.mode = 'side';
      }
    })

    // 1. Get user data
    // 2. Get account list from cookies
    this._userService.user$.pipe(
      takeUntil(this._unsubscribeAll)).subscribe((data) => {
        this.userData = data
        this.accountList = []
        let creds = this._utility.decryptAES(this._utility.getCookie(this.personalKey))
        let credData = JSON.parse(creds)
        for (let key in credData) {
          if ((this.userData.user_login.username.toString() != credData[key].username.toString())
            && credData[key].role == 1) {
            this.accountList.push({ ...credData[key] })
          }
        }
      })
    var bc = new BroadcastChannel('fcm_channel');
    bc.onmessage = (message) => {
      if (Notification.permission == 'granted')
        this._adminLayoutService.registerOwnerFCM(message.data)
    }
  }

  /**
   * After view load
   */
  ngAfterViewInit() {
    this._document.body.classList.add('spark-splash-screen-hidden');
  }

  /**
   * On destroy
   */
  ngOnDestroy() {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  /**
   * Setting language from dropdown
   * @param partnerLanguage
   */
  setLanguage(partnerLanguage): void {
    this._sparkConfigService.config = { partnerLanguage };
    localStorage.setItem('config', JSON.stringify(this.config))
    window.location.reload()
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Notifications
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get notification
   */
  getNotifications() {
    this._adminLayoutService.getNotifications(1, 0).then((data: any) => {
      if (data) {
        this.notifications =
          [
            {
              day: 'Today',
              data: data.data.filter((notification) => moment(notification.notified_at).isSame(new Date(), 'day'))
            },
            {
              day: 'Yesterday',
              data: data.data.filter((notification) => moment(notification.notified_at).isSame(moment().subtract(1, 'day'), 'day'))
            },
            {
              day: 'Earlier',
              data: data.data.filter((notification) => moment(notification.notified_at).isBefore(moment().subtract(1, 'day'), 'day'))
            }]
        this.unreadCount = data.total
      }
      else {
        this.notifications = []
        this.unreadCount = 0
      }
    })
  }

  calculateUnread() {
    if (this.notifications)
      return this.notifications.filter((val) => val.notification_status == 0).length
    else
      return 0;
  }

  markAllRead() {
    this._adminLayoutService.markAllRead().then((data) => {
      this.getNotifications()
    })
  }

  markRead(key, notificationStatus) {
    this._adminLayoutService.markRead(key, notificationStatus).then((data) => {
      this.getNotifications()
    })
  }

  removeNotification(key) {
    this._adminLayoutService.removeNotification(key).then((data) => {
      this.getNotifications()
    })
  }

  showAllNotifications() {
    const dialogRef = this.dialog.open(AllNotificationsComponent, {
      width: '500px',
      disableClose: true,
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getNotifications()
    });
  }

  /**
   * Menu type check
   */
  checkMenuTypeDialog() {
    const dialogRef = this.dialog.open(CheckMenuTypeModalComponent, {
      width: '500px',
      disableClose: true,
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe(result => {
      //this.getNotifications()
    });
  }

  /**
   * Tour
   */
  tourDialog() {
    const dialogRef = this.dialog.open(TourModalComponent, {
      width: '800px',
      backdropClass: 'tour-backdrop',
      disableClose: true,
      autoFocus: false,
    });

    return dialogRef;
  }

  /**
   * Share
   */
  shareDialog() {
    const dialogRef = this.dialog.open(ShareModalComponent, {
      width: '500px',
      disableClose: true,
      autoFocus: false,
    });

    dialogRef.afterClosed().subscribe(result => {
      //this.getNotifications()
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Sidebar
  // -----------------------------------------------------------------------------------------------------

  foldSidebar() {
    const isActive = this._mediaObserver.isActive('gt-sm')
    if (isActive) {
      this.isSidebarFold = !this.isSidebarFold;
      this._sparkNavigationService.onSidebarFold.next(this.isSidebarFold)
    }
    else {
      this.drawer.toggle();
    }
    if (this.isSidebarFold) {
      this.contentMargin = 70;
    }
    else {
      this.contentMargin = 270;
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Settings
  // -----------------------------------------------------------------------------------------------------

  openSettings() {
    this.rightDrawer.toggle();
  }

  setScheme(scheme): void {
    this._sparkConfigService.config = { scheme };
    localStorage.setItem('config', JSON.stringify(this.config))
  }

  setLangId(partnerLangKey): void {
    this._sparkConfigService.config = { partnerLangKey };
    localStorage.setItem('config', JSON.stringify(this.config))
  }

  setTheme(partnerTheme): void {
    this._sparkConfigService.config = { partnerTheme };
    localStorage.setItem('config', JSON.stringify(this.config))
  }

  setSession(idleTime): void {
    this._sparkConfigService.config = { idleTime };
    localStorage.setItem('config', JSON.stringify(this.config))
    this.idle.watch()
  }

  private _updateScheme(): void {
    this._document.body.classList.remove('light', 'dark');
    if (this.scheme == 'dark' ||
      this.scheme == 'light') {
      this._document.body.classList.add(this.scheme);
      this.overlayContainer.getContainerElement().classList.add(this.scheme);
    }
    else {
      this._document.body.classList.add('light');
      this.overlayContainer.getContainerElement().classList.remove(this.scheme);
    }
  }

  private _updateLayout(): void {

    if (
      this.config.layout == 'empty' ||
      this.config.layout == 'default') {
      let route = this._activatedRoute;
      while (route.firstChild) {
        route = route.firstChild;
      }
      this.layout = this.config.layout;
      const layoutFromQueryParam = (route.snapshot.queryParamMap.get('layout'));
      if (layoutFromQueryParam) {
        this.layout = layoutFromQueryParam;
        if (this.config) {
          this.config.layout = layoutFromQueryParam;
        }
      }

      // 3. Iterate through the paths and change the layout as we find
      // a config for it.
      //
      // The reason we do this is that there might be empty grouping
      // paths or componentless routes along the path. Because of that,
      // we cannot just assume that the layout configuration will be
      // in the last path's config or in the first path's config.
      //
      // So, we get all the paths that matched starting from root all
      // the way to the current activated route, walk through them one
      // by one and change the layout as we find the layout config. This
      // way, layout configuration can live anywhere within the path and
      // we won't miss it.
      //
      // Also, this will allow overriding the layout in any time so we
      // can have different layouts for different routes.

      const paths = route.pathFromRoot;
      paths.forEach((path) => {
        // Check if there is a 'layout' data
        if (path.routeConfig && path.routeConfig.data && path.routeConfig.data['layout']) {
          // Set the layout
          this.layout = path.routeConfig.data['layout'];
        }
      });
    }
    else {
      this.layout = 'default';
    }
  }

  private _updateTheme(): void {
    // Find the class name for the previously selected theme and remove it
    this._document.body.classList.forEach((className: string) => {
      if (className.startsWith('theme-')) {
        this._document.body.classList.remove(className, className.split('-')[1]);
      }
    });

    // Add class name for the currently selected theme
    this._document.body.classList.add(`theme-${this.partnerTheme}`);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Auth
  // -----------------------------------------------------------------------------------------------------


  /**
   * Logout partner
   */
  logout() {
    this._authService.signOut().finally(() => {
      this._utility.removeSession(this.userData.user_login.username, this.userData.user_login.user_role_name.user_role_drc_key)
      this._utility.goToLogin(environment.emenuDomain + '/partner', 'self')
    });
  }

  goToMyAccount(path?, redirectTab?) {
    let credData = this._utility.getSecret("personalSecret")
    let index = credData.findIndex((acc) => acc.username == this.userData.user_login.username)
    const url = new URL(environment.myaccountDomain + (path == 'product' ? '/product/' + environment.appSlug : ''));
    if (redirectTab)
      url.searchParams.set('redirectTab', redirectTab)
    url.searchParams.set('accIndex', index);
    window.open(url)
  }

  switchAccount(i) {
    let credData = this._utility.getSecret("personalSecret")
    let index = credData.findIndex((acc) => acc.username == this.accountList[i].username)
    const url = new URL(location.href);
    url.searchParams.set('accIndex', index);
    window.open(url, '_blank')
  }

  addNewAccount() {
    this._utility.goToLogin(window.location.href, 'blank')
  }
}
